import React, { useState, useEffect } from 'react';
import '../css/style.css';
import fetchDataFirebase from './service/fetchDataFirebase';





function ImageDisplay() {
    const [dataText, setDataText] = useState(null); // State to store fetched data (null initially)
    const [img, setImg] = useState(null)
    const [color,setColor] = useState(null);
  
  
    useEffect(() => {
        const fetchData = async () => {
            const dataText = await fetchDataFirebase('Datainfo','EN');
            const img =  await fetchDataFirebase('Banner', 'banner');
            const colorText = await fetchDataFirebase('Color', 'textcolor'); // เรียกใช้ฟังก์ชันกลาง
            if (img&&dataText&&colorText) {
                setDataText(dataText);
                setImg(img);
                setColor(colorText); // Update state with fetched data
            }   
        };
        fetchData();
    }, []); // Empty dependency array to fetch data only on component mount
  

    return (
        <div className="image-container">  {/* Add a container class */}
            <img src={img?.banner_1} alt={dataText?.aboutus?.data_aboutus} className="banner-image" />
            {/* Add a class name for styling the image */}
            <div className="image-text"> {/* Add a container for the text */}
                <div className='text-home' style={{color:color?.text_home}}>
                    <br></br>
                    <br></br>
                    <div > 
                        {dataText?.home?.data_cyphermines}
                    </div>
                </div>
                <br></br>
                <div class="button-container">
                    <button class="button-contact" style={{color:color?.text_buttoncontact}}>
                        <a href='/contact' style={{ textDecoration: 'none',color: 'black' }}>
                            {dataText?.contact?.header}</a>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ImageDisplay;
