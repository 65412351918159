

import React, { useState, useEffect } from 'react';
import firebase from '../firebase'; // Assuming your Firebase configuration is in "../firebase"
import '../css/style.css';


const db = firebase.firestore();

function POS() {
    const [data, setData] = useState(null); // State to store fetched data (null initially)
    const [img, setImg] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            try {

                // Get a reference to the specific document
                const docRef = db.collection('Investment').doc("investment"); // Replace 'EN' with your actual document ID
                const docSnapshot = await docRef.get();
                if (docSnapshot.exists) {
                    setImg(docSnapshot.data()); // Update state with fetched data
                } else {
                    console.log('Document not found');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle errors (e.g., display an error message in UI)
            }
        };
        fetchData();
    }, []); // Empty dependency array to fetch data only on component mount


    useEffect(() => {
        const fetchData = async () => {
            try {
                // Get a reference to the specific document for text data based on language
                const docRef = db.collection('Datainfo').doc("EN");
                const docSnapshot = await docRef.get();
                if (docSnapshot.exists) {
                    setData(docSnapshot.data()); // Update state with fetched data
                } else {
                    console.log('Text data document not found');
                }
            } catch (error) {
                console.error('Error fetching text data:', error);
                // Handle errors (e.g., display an error message in UI)
            }
        };
        fetchData();
    }, []);

    return (
        <div className='aboutus'>
            <br></br>
            <div>
                <div className='p'>
                    {data?.home?.our_investment}
                </div>
                <div className='container-row'>
                    <a href={img?.link_investment1} target="_blank">
                        <img src={img?.img_investment1} alt="img" className="img-investment" />
                    </a>
                    <a href={img?.link_investment2} target="_blank">
                        <img src={img?.img_investment2} alt="img" className="img-investment" />
                    </a>
                    <a href={img?.link_investment3} target="_blank">
                        <img src={img?.img_investment3} alt="img" className="img-investment" />
                    </a>
                    <a href={img?.link_investment4} target="_blank">
                        <img src={img?.img_investment4} alt="img" className="img-investment" />
                    </a>
                </div>
                <div className='container-row'>
                    <a href={img?.link_investment5} target="_blank">
                        <img src={img?.img_investment5} alt="img" className="img-investment" />
                    </a>
                    <a href={img?.link_investment6} target="_blank">
                        <img src={img?.img_investment6} alt="img" className="img-investment" />
                    </a>
                    <a href={img?.link_investment7} target="_blank">
                        <img src={img?.img_investment7} alt="img" className="img-investment" />
                    </a>
                    <a href={img?.link_investment8} target="_blank">
                        <img src={img?.img_investment8} alt="img" className="img-investment" />
                    </a>

                </div>
                <div className='container-row'>
                    <a href={img?.link_investment9} target="_blank">
                        <img src={img?.img_investment9} alt="img" className="img-investment" />
                    </a>
                    <a href={img?.link_investment10} target="_blank">
                        <img src={img?.img_investment10} alt="img" className="img-investment" />
                    </a>
                    <a href={img?.link_investment11} target="_blank">
                        <img src={img?.img_investment11} alt="img" className="img-investment" />
                    </a>
                    <a href={img?.link_investment12} target="_blank">
                        <img src={img?.img_investment12} alt="img" className="img-investment" />
                    </a>

                </div>
                <div className='container-row'>
                    <a href={img?.link_investment13} target="_blank">
                        <img src={img?.img_investment13} alt="img" className="img-investment" />
                    </a>
                    {/* <a href={data?.link_investment2} target="_blank">
                    <img src={data?.img_investment2} alt="img" className="img-investment" />
                </a>
                <a href={data?.link_investment3} target="_blank">
                    <img src={data?.img_investment3} alt="img" className="img-investment" />
                </a>
                <a href={data?.link_investment4} target="_blank">
                    <img src={data?.img_investment4} alt="img" className="img-investment" />
                </a> */}

                </div>
            </div>
            <div className='container-home'></div>
            <div className='text-contact'>
                <div className='p-text-sub'>
                    © 2018-2020 CypherMines Corporation. All Rights Reserved
                </div>
            </div>
        </div>

    );

}

export default POS;
