

import React, { useState, useEffect } from 'react';
import firebase from '../firebase'; // Assuming your Firebase configuration is in "../firebase"
import '../css/style.css';


const db = firebase.firestore();

function CypherLabs() {
    const [data, setData] = useState(null); // State to store fetched data (null initially)

    useEffect(() => {

        const fetchData = async () => {
            try {

                // Get a reference to the specific document
                const docRef = db.collection('CypherLabs').doc("cypherlabs"); // Replace 'EN' with your actual document ID
                const docSnapshot = await docRef.get();
                if (docSnapshot.exists) {
                    setData(docSnapshot.data()); // Update state with fetched data
                } else {
                    console.log('Document not found');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle errors (e.g., display an error message in UI)
            }
        };
        fetchData();
    }, []); // Empty dependency array to fetch data only on component mount
    return (
        <div>
             <div className='container-row'>
                <a href={data?.link_cypherlabs1} target="_blank">
                    <img src={data?.img_cypherlabs1} alt="img" className="img-investment" />
                </a>
                <a href={data?.link_cypherlabs2} target="_blank">
                    <img src={data?.img_cypherlabs2} alt="img" className="img-investment" />
                </a>
                <a href={data?.link_cypherlabs3} target="_blank">
                    <img src={data?.img_cypherlabs3} alt="img" className="img-investment" />
                </a>
                <a href={data?.link_cypherlabs4} target="_blank">
                    <img src={data?.img_cypherlabs4} alt="img" className="img-investment" />
                </a>
               
            </div>
        
            <div className='container-row'>
                <a href={data?.link_cypherlabs5} target="_blank">
                    <img src={data?.img_cypherlabs5} alt="img" className="img-investment" />
                </a>
                <a href={data?.link_cypherlabs6} target="_blank">
                    <img src={data?.img_cypherlabs6} alt="img" className="img-investment" />
                </a>
                <a href={data?.link_cypherlabs7} target="_blank">
                    <img src={data?.img_cypherlabs7} alt="img" className="img-investment" />
                </a>
                <a href={data?.link_cypherlabs8} target="_blank">
                    <img src={data?.img_cypherlabs8} alt="img" className="img-investment" />
                </a>
               
            </div>
        </div>
    );

}

export default CypherLabs;
