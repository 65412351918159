import ImageDisplay from './ImageDisplayHome';
import React, { useState, useEffect } from 'react';
import '../css/style.css';
import { GoDotFill } from "react-icons/go";
import Investment from './Investment';
import Watchlis from './Watchlist';
import CypherLabs from './CypherLabs';
import OurTeam from './OurTeam';
import ContactForm from './ContactForm'
import fetchDataFirebase from './service/fetchDataFirebase';




function Home() {
  const [dataText, setDataText] = useState(null); // State to store fetched data (null initially)
  const [img, setImg] = useState(null)
  const [color,setColor] = useState(null);


  useEffect(() => {
      const fetchData = async () => {
          const dataText = await fetchDataFirebase('Datainfo','EN');
          const img =  await fetchDataFirebase('Banner', 'banner');
          const colorText = await fetchDataFirebase('Color', 'textcolor'); // เรียกใช้ฟังก์ชันกลาง
          if (img&&dataText) {
              setDataText(dataText);
              setImg(img);
              setColor(colorText); // Update state with fetched data
          }   
      };
      fetchData();
  }, []); // Empty dependency array to fetch data only on component mount


  return (
    <div>
      <ImageDisplay />
      <div className='container-home'></div>
      <br></br>
      <div className='col-md-12 bottomleft'>
        <div className='p' style={{color:color?.text_p}}>
          {dataText?.home?.aboutus}
          <br></br>
          <div className='p-text-sub'>
            {dataText?.home?.data_aboutus}
          </div>
          <div className='container-home'></div>
          {dataText?.home?.businesses}
          <div className='p-text-sub'>
            <GoDotFill />
            {dataText?.home?.data_businesses1}
            <br></br>
            <GoDotFill />
            {dataText?.home?.data_businesses2}
            <br></br>
            <GoDotFill />
            {dataText?.home?.data_businesses3}
            <br></br>
            <GoDotFill />
            {dataText?.home?.data_businesses4}
            <br></br>
            <GoDotFill />
            {dataText?.home?.data_businesses5}
          </div>
         
          <div className='container-home'></div>
        </div>
      </div>
      <section id='pow'>
      <div className='background-home'>
        <div className='image-text-container'>  <img src={img?.banner2} alt={dataText?.aboutus?.data_aboutus} className='img-home' />
          <div className='image-text-home' style={{color:color?.text_home}}> " {dataText?.pow?.data_pow} " </div>
        </div>
      </div>
      </section>
      <div className='container-home'></div>
      <div className='p' style={{color:color?.text_p}}>
        {dataText?.home?.our_investment}
      </div>
      <Investment/>
      <div className='container-home'></div>
      <div className='p' style={{color:color?.text_p}}>
        {dataText?.home?.watchlis}
      </div>
      <Watchlis/>
      <div className='container-home'></div>
      <div className='p' style={{color:color?.text_p}}>
        {dataText?.home?.cypherlabs}
      </div>
      <CypherLabs/>
      <div className='container-home'></div>
      <OurTeam/>
      <div className='container-home'></div>
      <ContactForm/>
      <div className='container-home'></div>
      <div className='text-contact'>
        <div className='p-text-sub'>
        © 2018-2020 CypherMines Corporation. All Rights Reserved
        </div>
      </div>
    </div>
  );
}
export default Home;
