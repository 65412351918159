import React, { useState, useEffect } from 'react';
import '../css/style.css';
import { GoDotFill } from "react-icons/go";
import fetchDataFirebase from './service/fetchDataFirebase';



function OurTeam() {
  const [dataText, setDataText] = useState(null); // State to store fetched data (null initially)
  const [img, setImg] = useState(null)
  const [color,setColor] = useState(null);
  useEffect(() => {
      const fetchData = async () => {
          const dataText = await fetchDataFirebase('Datainfo','EN');
          const img =  await fetchDataFirebase('Banner', 'banner');
          const colorText = await fetchDataFirebase('Color', 'textcolor'); // เรียกใช้ฟังก์ชันกลาง
          if (colorText &&img&&dataText) {
              setDataText(dataText);
              setImg(img);
              setColor(colorText); // Update state with fetched data
          }   
      };
      fetchData();
  }, []); 
    return (
        <div>
            <div className='p' style={{color:color?.text_p}} >
                {dataText?.home?.Our_Team}
                <div className='p-text-sub'>
                <GoDotFill />
                {dataText?.home?.header_Our_Team1}
                &nbsp;
                {dataText?.home?.data_Our_Team1}
                <br></br>
                <GoDotFill />
                {dataText?.home?.header_Our_Team2}
                &nbsp;
                {dataText?.home?.data_Our_Team2}
                <br></br>
                <GoDotFill />
                {dataText?.home?.header_Our_Team3}
                &nbsp;
                {dataText?.home?.data_Our_Team3}
                <br></br>
                <GoDotFill />
                {dataText?.home?.header_Our_Team4}
                &nbsp;
                {dataText?.home?.data_Our_Team4}
                <br></br>
                <GoDotFill />
                {dataText?.home?.header_Our_Team5}
                &nbsp;
                {dataText?.home?.data_Our_Team5}
                <br></br>
                <GoDotFill />
                {dataText?.home?.header_Our_Team6}
                &nbsp;
                {dataText?.home?.data_Our_Team6}
            </div>
            </div>
           
            <br></br>
            <img src={img?.banner4} alt='img' className="banner-image" />
        </div>
    );
}

export default OurTeam;
