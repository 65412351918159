import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'; // Import Firestore specifically

// Import other Firebase features as needed (optional):
// import 'firebase/compat/storage';
// import 'firebase/auth'; // if you're using authentication
// import 'firebase/database'; // if you're using Realtime Database

const firebaseConfig = {
  apiKey: "AIzaSyAg6RdNWl9nPDyJJ9WJaVajf6kzKM4aCdU",
  authDomain: "cyphermines-web-info.firebaseapp.com",
  projectId: "cyphermines-web-info",
  storageBucket: "cyphermines-web-info.appspot.com",
  messagingSenderId: "664925299486",
  appId: "1:664925299486:web:dc67bb692358200241df0f",
  measurementId: "G-Q4EKZQSW3X"
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore(); // Access Firestore instance
// export const storage = firebase.storage(); // if you need Storage
export default firebase; // if you need the entire Firebase app instance (less common)

