// import React from "react";
//  // Replace with your About Us component path
// import Header from "./components/Header";

// export default function App() {
//   return (
//     <div className="App">

//       <Header/>
//     </div>

//   );
// }
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import Aboutus from "./components/Aboutus";
import Contact from "./components/ContactUs";
import POS from "./components/POS";
import './css/style.css'
import fetchDataFirebase from './components/service/fetchDataFirebase';


function App() {
  const [bgColor,setbgColor] = useState(null);
  useEffect(() => {
      const fetchData = async () => {

          const colorBG =  await fetchDataFirebase('Color', 'backgroundcolor');
          // console.log(colorBG);
          if ( colorBG) {
              setbgColor(colorBG);
          }
      };
      fetchData();
  }, []);
  return (
    <div className="App">
      <div style={{backgroundColor:bgColor?.container_body}}>
        <BrowserRouter>
          <header>
            <Header />
          </header>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/contact" element ={<Contact/>}/>
            <Route path="/pos" element ={<POS/>}/>
          </Routes>
        </BrowserRouter>
      </div>

    </div>
  );
}

export default App;

