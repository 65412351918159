import React, { useState, useEffect } from 'react';
import '../css/style.css';
import fetchDataFirebase from './service/fetchDataFirebase';

function Aboutus() {
    const [dataText, setDataText] = useState(null); // State to store fetched data (null initially)
    const [img, setImg] = useState(null)
    const [color,setColor] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            const dataText = await fetchDataFirebase('Datainfo','EN');
            const img =  await fetchDataFirebase('Banner', 'banner');
            const colorText = await fetchDataFirebase('Color', 'textcolor'); // เรียกใช้ฟังก์ชันกลาง
            if (colorText &&img&&dataText) {
                setDataText(dataText);
                setImg(img);
                setColor(colorText); // Update state with fetched data
            }   
        };
        fetchData();
    }, []);

    return (
        <section id="aboutus">
            <div className="aboutus">
                <br></br>
                <div className="p" style={{color:color?.text_p}}>
                    {dataText?.home?.aboutus}
                    <br />
                    <div className="container-aboutus-text" style={{color:color?.text_aboutus}}>
                        {dataText?.home?.data_aboutus}
                    </div>
                    <img
                        src={img?.banner2}
                        alt="img"
                        className="image-below-text"
                    />
                    <div className='container-home'></div>
                   
                </div>
                <div className='text-contact'>
                        <div className='p-text-sub' style={{color:color?.text_sub}}>
                            © 2018-2020 CypherMines Corporation. All Rights Reserved
                        </div>
                    </div>
            </div>

        </section>

    );

}

export default Aboutus;
