

import React, { useState, useEffect } from 'react';
import firebase from '../firebase'; // Assuming your Firebase configuration is in "../firebase"
import '../css/style.css';


const db = firebase.firestore();

function Watchlis() {
    const [data, setData] = useState(null); // State to store fetched data (null initially)

    useEffect(() => {

        const fetchData = async () => {
            try {

                // Get a reference to the specific document
                const docRef = db.collection('Watchlist').doc("watchlis"); // Replace 'EN' with your actual document ID
                const docSnapshot = await docRef.get();
                if (docSnapshot.exists) {
                    setData(docSnapshot.data()); // Update state with fetched data
                } else {
                    console.log('Document not found');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle errors (e.g., display an error message in UI)
            }
        };
        fetchData();
    }, []); // Empty dependency array to fetch data only on component mount
    return (
        <div>
            <div className='container-row'>
                <a href={data?.link_watchlis1} target="_blank">
                    <img src={data?.img_watchlis1} alt="img" className="img-investment" />
                </a>
                {/* <a href={data?.link_investment2} target="_blank">
                    <img src={data?.img_investment2} alt="img" className="img-investment" />
                </a>
                <a href={data?.link_investment3} target="_blank">
                    <img src={data?.img_investment3} alt="img" className="img-investment" />
                </a>
                <a href={data?.link_investment4} target="_blank">
                    <img src={data?.img_investment4} alt="img" className="img-investment" />
                </a>
                */}
            </div>
    
        </div>
    );

}

export default Watchlis;
