import React, { useState, useEffect } from 'react';
import '../css/style.css';
import logo from '../assets/img/logo-white.png';
import { Link } from 'react-router-dom';
import fetchDataFirebase from './service/fetchDataFirebase';


function Header() {
    const [dataText, setData] = useState(null); // State to store fetched data (null initially)

    const [color,setColor] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const dataText =  await fetchDataFirebase('Datainfo', 'EN');
            const data = await fetchDataFirebase('Color', 'textcolor'); // เรียกใช้ฟังก์ชันกลาง
            if (data && dataText ) {
                setData(dataText);
                setColor(data); // Update state with fetched data
            }
        };
        fetchData();
    }, []);
    return (
        <nav className="navbar">
            <Link to= "/">
              <div className="navbar-logo-container">
                <img src={logo} alt="Company Logo" className="navbar-logo" />
                <span className="navbar-logo-text" style={{color:color?.text_logo}}>{dataText?.home?.cyphermines}</span>
            </div>  
            </Link>
        
            <div className="navbar-link-container" >
                <div className="navbar-links" style={{color: color?.text_navbar}}>
                    <Link to="/"  >{dataText?.header?.HOME}</Link>
                    <Link to="/aboutus">{dataText?.header?.ABOUTUS}</Link>
                    <Link to="/pow">{dataText?.header?.POW}</Link>
                    <Link to="/pos">{dataText?.header?.POS}</Link>
                </div>
            </div>
        </nav>
    );
}

export default Header;
