import React, { useState, useEffect } from 'react';
import '../css/style.css';
// import { AiFillCaretRight } from "react-icons/ai";
import { MdMarkEmailUnread } from "react-icons/md";

import fetchDataFirebase from './service/fetchDataFirebase';
// function ContactUs() {
//     const [data, setData] = useState(null); // State to store fetched data (null initially)


//     useEffect(() => {

//         const fetchData = async () => {
//             try {

//                 // Get a reference to the specific document
//                 const docRef = db.collection('Datainfo').doc("EN"); // Replace 'EN' with your actual document ID
//                 const docSnapshot = await docRef.get();
//                 if (docSnapshot.exists) {
//                     setData(docSnapshot.data()); // Update state with fetched data
//                 } else {
//                     console.log('Document not found');
//                 }
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//                 // Handle errors (e.g., display an error message in UI)
//             }
//         };
//         fetchData();
//     }, []); // Empty dependency array to fetch data only on component mount

//     return (
//         <div>
//             <div className='text-bu'>
//                 <AiFillCaretRight />
//                 {data?.home?.contactus}
//             </div>
//             <MdMarkEmailUnread />
//             {data?.home?.email}
//         </div>
//     );
// }

// export default ContactUs;


function ContactForm() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [dataText, setDataText] = useState(null);


    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission

        const data = {
            name,
            email,
            phone,
            message,
        };
        console.log(data);

        try {
            //   const response = await axios.post('[YOUR_SENDGRID_API_URL]', data, {
            //     headers: {
            //       Authorization: `Bearer ${YOUR_SENDGRID_API_KEY}`, // Replace with your SendGrid API key
            //     },
            //   });
            //   console.log('Email sent successfully:', response);
            alert('Email sent successfully!'); // Notify the user
            // Clear the form fields after successful submission
            setName('');
            setEmail('');
            setPhone('');
            setMessage('');

        } catch (error) {
            console.error('Error sending email:', error);
            alert('Failed to send email. Please check the console for more details.');
        }
    };

    // const [dataText, setDataText] = useState(null); // State to store fetched data (null initially)
    const [color,setColor] = useState(null);
    const [colorBG,setColorBG] = useState(null);
  
  
    useEffect(() => {
        const fetchData = async () => {
            const dataText = await fetchDataFirebase('Datainfo','EN');
            const bg = await fetchDataFirebase('Color', 'backgroundcolor');
            const colorText = await fetchDataFirebase('Color', 'textcolor'); // เรียกใช้ฟังก์ชันกลาง
            if (dataText&&colorText&&bg) {
                setDataText(dataText);
                setColorBG(bg);
                setColor(colorText); // Update state with fetched data
            }   
        };
        fetchData();
    }, []); 
    return (
        <section id='contactform'>
            <div >
                <div className='p' style={{color:color?.text_p}}>
                    {dataText?.contact?.header}
                    <div className='p-text-sub'>
                    {dataText?.contact.email}
                    <MdMarkEmailUnread />
                </div>
                </div>
                <div className="form-container"style={{color:color?.text_sub}}>
                    <div className="form-group">
                        <label for="name"> <div className='p-text-sub' >
                            NAME:
                        </div></label>
                        <input type="text" id="name" name="name" required />
                    </div>
                    <div className="form-group">
                        <label for="email"> <div className='p-text-sub'>
                            EMAIL:
                        </div></label>
                        <input type="email" id="email" name="email" required />
                    </div>
                    <div className="form-group">
                        <label for="phone"> <div className='p-text-sub'>
                            PHONE:
                        </div></label>
                        <input type="text" id="phone" name="phone" required />
                    </div>

                    <div className="form-group">
                        <label for="message"> <div className='p-text-sub'>
                            MESSAGE:
                        </div></label>
                        <textarea id="message" name="message" required></textarea>
                    </div>

                    <button type="submit" onClick={handleSubmit} style={{color:colorBG?.border}}>Send</button>
                </div>
                {/* <div className='container-home'></div>
                <div className='text-contact'>
                    <div className='p-text-sub'>
                        © 2018-2020 CypherMines Corporation. All Rights Reserved
                    </div>
                </div> */}
            </div>
        </section>
    );
}

export default ContactForm;
