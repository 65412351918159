import { db } from "../../firebase";

const fetchDataFirebase = async (collectionId, documentId) => {
    try {
        // const db = firebase.firestore();

      // Get a reference to the specific document
      // const docRef = db.collection(collectionId).doc(documentId);
      console.log(collectionId);
      // let docRef = db
      //   .collection("cypherminewebinfo")
      //   .doc(collectionId)
      //   .collection(collectionId)
      //   .doc(collectionId.toLowerCase())
      //   .doc(documentId);

      let docRef = db
        .collection("cypherminewebinfo")
        .doc(collectionId)
        .collection(collectionId)
        .doc(documentId);
      
      // let docRef = db
      //   .collection("cypherminewebinfo")
      //   .doc(collectionId)
      //   .doc(documentId);
      // if (collectionId === "Color") {
      //   docRef = db
      //     // /cypherminewebinfo/Color/Color/backgroundcolor
      //     .collection("cypherminewebinfo")
      //     .doc(collectionId)
      //     .collection("Color")
      //     .doc(documentId);
         
      // }
      // else if (collectionId === "Banner"){
      //   docRef = db
      //     .collection("cypherminewebinfo")
      //     .doc(collectionId)
      //     .collection(collectionId)
      //     .doc(documentId);
      // }
      
      // else{
      //   docRef = db.collection("cypherminewebinfo").doc(collectionId);
      // }
     
        

      const docSnapshot = await docRef.get();

       console.log(docSnapshot);
  
      if (docSnapshot.exists) {
        return docSnapshot.data(); // Return fetched data
      } else {
        console.log('Document not found');
        return null; // Return null if document not found
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle errors (e.g., display an error message in UI)
      return null; // Return null on error
    }
  };
  
export default fetchDataFirebase;